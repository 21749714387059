import React from 'react'
import Router from 'react-router'
import mui from 'material-ui'

var {Menu} = mui

export default React.createClass({

  mixins: [Router.Navigation, Router.ActiveState],

  propTypes: {
    menuItems: React.PropTypes.array
  },

  render: function() {
    var page = React.createElement(this.props.activeRouteHandler, {data: this.props.data})
    if (this.props.error) 
      page = React.createElement("div", {className: "error"}, this.props.error)

    return (
      React.createElement("div", {className: "mui-app-content-canvas page-with-nav"}, 
        React.createElement("div", {className: "page-with-nav-content"}, 
          page
        ), 
        React.createElement("div", {className: "page-with-nav-secondary-nav"}, 
          React.createElement(Menu, {
            ref: "menuItems", 
            zDepth: 0, 
            menuItems: this.props.menuItems, 
            selectedIndex: this._getSelectedIndex(), 
            onItemClick: this._onMenuItemClick})
        )
      )
    );
  },

  _getSelectedIndex: function() {
    var menuItems = this.props.menuItems,
      currentItem;

    for (var i = menuItems.length - 1; i >= 0; i--) {
      currentItem = menuItems[i];
      if (currentItem.route && this.isActive(currentItem.route)) return i;
    };
  },

  _onMenuItemClick: function(e, index, item) {
    this.transitionTo(item.route);
  }
  
});