/** @jsx React.DOM */

var Classable = require('./mixins/classable');
var Paper = require('./paper.jsx');
var React = require('react');

var Dialog = React.createClass({displayName: 'Dialog',

  mixins: [Classable],

  propTypes: {
    openImmediately: React.PropTypes.bool,
    title: React.PropTypes.string,
    actions: React.PropTypes.array,
    onShow: React.PropTypes.func
  },

  getDefaultProps: function() {
    return {
      actions: []
    };
  },

  getInitialState: function() {
    return {
      open: this.props.openImmediately || false
    };
  },

  componentDidUpdate: function (prevProps, prevState) {
    //calculate height and use that to center the dialog vertically
    var dom = this.getDOMNode(),
      height = dom.offsetHeight;

    dom.style.marginTop = -1 * height / 2 + 'px';
  },

  _handleClickAway: function() {
    this.dismiss();
  },

  render: function() {
    var mainClasses = this.getClasses('dialog', { 'show': this.state.open }),
      actions = this.props.actions.map(function(a) {
        if (a.onClick) return React.createElement("div", {className: "action", onClick: a.onClick}, a.text);
        return React.createElement("div", {className: "action", onClick: this.dismiss}, a.text);
      }.bind(this));

    return (
      React.createElement("div", {className: mainClasses}, 
        React.createElement(Paper, {zDepth: 4}, 
          React.createElement("h3", {className: "dialog-title"}, 
            this.props.title
          ), 
          React.createElement("div", {className: "dialog-content"}, 
            this.state.open ? this.props.children : ''
          ), 
          React.createElement("div", {className: "dialog-actions"}, 
            React.createElement("div", {className: "actions-right"}, 
              actions
            )
          )
        ), 
        React.createElement("div", {className: "dialog-overlay", onClick: this._handleClickAway})
      )
    );
  },

  dismiss: function() {
    this.setState({ open: false });
  },

  show: function() {
    this.setState({ open: true });
    if (this.props.onShow) this.props.onShow();
  }

});

module.exports = Dialog;
