import React from 'react'
import moment from 'moment'

export default React.createClass({
  render: function() {
    var {user} = this.props.data;
    return (
      React.createElement("div", null, 
        React.createElement("h2", null, "Users"), 
        React.createElement("p", null, "Total: ", user.total), 
        React.createElement("p", null, "Today: ", user.today), 
        React.createElement("ul", null, 
          user.recent.map(function(account) {
            var created = new Date(parseInt(account._id.substring(0,8),16)*1000);
            return (
              React.createElement("li", {key: account._id}, 
                moment(created).fromNow(), " ", 
                /* TODO: don't use `app` */
                React.createElement("a", {href: "https://www.thinkero.us/"+"app"+"/user/"+account._id}, 
                  React.createElement("b", null, account.displayName)
                )
              )
            )
          })
        )
      )
    );
  }
});