module.exports = {
  AppBar: require('./js/app-bar.jsx'),
  AppCanvas: require('./js/app-canvas.jsx'),
  Checkbox: require('./js/checkbox.jsx'),
  Dialog: require('./js/dialog.jsx'),
  DropDownIcon: require('./js/drop-down-icon.jsx'),
  DropDownMenu: require('./js/drop-down-menu.jsx'),
  Icon: require('./js/icon.jsx'),
  Input: require('./js/input.jsx'),
  LeftNav: require('./js/left-nav.jsx'),
  MenuItem: require('./js/menu-item.jsx'),
  Menu: require('./js/menu.jsx'),
  Mixins: {
    Classable: require('./js/mixins/classable.js'),
    ClickAwayable: require('./js/mixins/click-awayable.js')
  },
  PaperButton: require('./js/paper-button.jsx'),
  Paper: require('./js/paper.jsx'),
  RadioButton: require('./js/radio-button.jsx'),
  Ripple: require('./js/ripple.jsx'),
  Toggle: require('./js/toggle.jsx'),
  Toast: require('./js/toast.jsx'),
  Toolbar: require('./js/toolbar.jsx'),
  ToolbarGroup: require('./js/toolbar-group.jsx')
};
