/**
 * @jsx React.DOM
 */

var React = require('react'),
  Classable = require('./mixins/classable.js'),
  ClickAwayable = require('./mixins/click-awayable'),
  KeyLine = require('./utils/key-line.js'),
  Paper = require('./paper.jsx'),
  Icon = require('./icon.jsx'),
  Menu = require('./menu.jsx');

var DropDownMenu = React.createClass({displayName: 'DropDownMenu',

  mixins: [Classable, ClickAwayable],

  propTypes: {
    onChange: React.PropTypes.func,
    menuItems: React.PropTypes.array.isRequired
  },

  getInitialState: function() {
    return {
      open: false,
      selectedIndex: this.props.selectedIndex || 0
    }
  },

  componentClickAway: function() {
    this.setState({ open: false });
  },

  componentDidMount: function() {
    var dom = this.getDOMNode(),
      menuItemsDom = this.refs.menuItems.getDOMNode();

    dom.style.width = menuItemsDom.offsetWidth + 'px';
  },

  componentWillReceiveProps: function(nextProps) {
    if (nextProps.hasOwnProperty('selectedIndex')) this.setState({selectedIndex: nextProps.selectedIndex});
  },

  render: function() {
    var classes = this.getClasses('mui-drop-down-menu', {
      'mui-open': this.state.open
    });

    return (
      React.createElement("div", {className: classes}, 
        React.createElement("div", {className: "mui-menu-control", onClick: this._onControlClick}, 
          React.createElement(Paper, {className: "mui-menu-control-bg", zDepth: 0}), 
          React.createElement("div", {className: "mui-menu-label"}, 
            this.props.menuItems[this.state.selectedIndex].text
          ), 
          React.createElement(Icon, {className: "mui-menu-drop-down-icon", icon: "navigation-arrow-drop-down"}), 
          React.createElement("div", {className: "mui-menu-control-underline"})
        ), 
        React.createElement(Menu, {ref: "menuItems", selectedIndex: this.state.selectedIndex, menuItems: this.props.menuItems, hideable: true, visible: this.state.open, onItemClick: this._onMenuItemClick})
      )
    );
  },

  _onControlClick: function(e) {
    this.setState({ open: !this.state.open });
  },

  _onMenuItemClick: function(e, key, payload) {
    if (this.props.onChange && this.state.selectedIndex !== key) this.props.onChange(e, key, payload);
    this.setState({
      selectedIndex: key,
      open: false
    });
  }

});

module.exports = DropDownMenu;
