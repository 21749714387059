import React from 'react'
import moment from 'moment'

export default React.createClass({
  render: function() {
    var {group} = this.props.data;

    return (
      React.createElement("div", null, 
        React.createElement("h2", null, "Groups"), 
        React.createElement("p", null, "Total: ", group.total), 
        React.createElement("p", null, "Today: ", group.today), 
        React.createElement("ul", null, 
          group.recent.map(function(group) {
            var created = new Date(parseInt(group._id.substring(0,8),16)*1000);
            return (
              React.createElement("li", {key: group._id}, 
                moment(created).fromNow(), " ", 
                React.createElement("a", {href: "https://www.thinkero.us/"+group.subdomain}, 
                  React.createElement("b", null, group.name
                  )
                )
              )
            )
          })
        )
      )
    );
  }
});