import React from 'react'
import moment from 'moment'

export default React.createClass({
  render: function() {
    var type = (this.props.projectType === "Issues") ? 'issue':'idea';
    var projects = this.props.data[type];

    return (
      React.createElement("div", null, 
        React.createElement("h2", null, this.props.projectType), 
        React.createElement("p", null, "Total: ", projects.total), 
        React.createElement("p", null, "Today: ", projects.today), 
        React.createElement("ul", null, 
          projects.recent.map(function(project) {
            var created = new Date(parseInt(project._id.substring(0,8),16)*1000);
            return (
              React.createElement("li", {key: project._id}, 
                moment(created).fromNow(), " ", 
                React.createElement("a", {href: "https://www.thinkero.us/"+project._group+"/projects/"+project._id}, 
                  React.createElement("b", null, project.title
                  )
                )
              )
            )
          })
        )
      )
    );
  }
});