import React from 'react'
import Router from 'react-router'

import App from './components/App'
import Overview from './pages/overview'
import Accounts from './pages/accounts'
import Projects from './pages/projects'
import Groups from './pages/groups'

var {Route, Routes, DefaultRoute} = Router;

React.render(
  React.createElement(Routes, null, 
    React.createElement(Route, {path: "/", handler: App, url: "https://www.thinkero.us/dashboard_api"}, 
      React.createElement(DefaultRoute, {name: "overview", handler: Overview}), 
      React.createElement(Route, {name: "accounts", handler: Accounts}), 
      React.createElement(Route, {name: "issues", handler: Projects, projectType: "Issues"}), 
      React.createElement(Route, {name: "ideas", handler: Projects, projectType: "Ideas"}), 
      React.createElement(Route, {name: "groups", handler: Groups})
    )
  ),
  document.body
);
