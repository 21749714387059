function withoutProperties(object, properties) {
  var result = {};

  for (var property in object) {
    if (object.hasOwnProperty(property) && !properties[property])
      result[property] = object[property];
  }

  return result;
}

module.exports = withoutProperties;
