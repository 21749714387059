import React from 'react'
import moment from 'moment'


export default React.createClass({
  render: function() {
    var data = this.props.data;
    return (
      React.createElement("div", null, 
        React.createElement("h2", null, "Overview"), 
        React.createElement("ul", null, 
          Object.keys(data).map(function(key) {
            var created = "Never";
            if (data[key] && data[key].recent && data[key].recent.length > 0) {
              created = new Date(parseInt(data[key].recent[0]._id.substring(0,8),16)*1000);
              created = moment(created).fromNow();
            } 

            return (
              React.createElement("li", {key: key}, 
                React.createElement("h4", null, key), 
                React.createElement("p", null, "Total: ", data[key].total), 
                React.createElement("p", null, "Today: ", data[key].today), 
                React.createElement("p", null, "Last updated: ", created)
              )
            );
          })
        )
      )
    );
  }
});